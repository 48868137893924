import { Route, Routes } from "react-router-dom";
import Delivery from "./container/dashboard/components/Delivery";
import Dashboard from "./container/dashboard/Dashboard";
import TrackingShipment from "./container/Tracking";
import TrackingDetails from "./container/Tracking-Details";
import "./styles/dashboard.css";
import "./styles/tracking.css";
import "./styles/tracking-list.css";
import "./App.css";
import RouterContextWrap from "./utils/context/RouterContext";
import TrackingList from "./container/TrackingList";
import FindTracking from "./container/Find-Tracking";
import MapComponent from "./container/MapContainer";
import Home from "./container/Frontend/Home/Home";
import About from "./container/Frontend/About/About";
import Contact from "./container/Frontend/Contact/Contact";
import TrackBox from "./container/Frontend/Components/TrackBox";
import ScrollToTop from "./container/Frontend/Components/scroll";
import CreateRoute from "./container/dashboard/components/CreateRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Blocker from "./container/dashboard/components/Blocker";

function App() {
  //   window.api = "http://localhost:5000";
  window.api = "https://xzibit-server-qq4n.onrender.com";
  return (
    <div className="App">
      <ScrollToTop />
      <ToastContainer />
      {/* <Blocker /> */}
      <RouterContextWrap>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/tracking/id/" element={<TrackingShipment />} />
          <Route path="/track" element={<TrackBox />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />

          {/* Dashboard Route */}
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create-route" element={<CreateRoute />} />
          <Route path="/dashboard/delivery" element={<Delivery />} />
          <Route path="/tracking/details" element={<TrackingDetails />} />
          <Route path="/tracking/list" element={<TrackingList />} />
          <Route path="/tracking/search" element={<FindTracking />} />
          <Route path="/map" element={<MapComponent />} />
        </Routes>
      </RouterContextWrap>
    </div>
  );
}

export default App;
