import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RouterContext } from "../../../utils/context/RouterContext";
// import route from "../../../routes/syria-india.json";
import { post } from "../../../utils/postCaller";
import RouteSkeleton from "./route-skeleton";
import { get } from "../../../utils/get";

export default function Route() {
  const { state, dispatch } = useContext(RouterContext);

  // const [route, setRoute] = useState("");
  const [routeData, setRouteData] = useState({ route: [] });
  const [showRoute, setShowRoute] = useState(false);

  const [pickup, setPickup] = useState("");
  const [destination, setDestination] = useState("");

  const [trackingErrorMessage, setTrackingErrorMessage] = useState(false);
  const [routeErrorMessage, setRouteErrorMessage] = useState(false);

  const navi = useNavigate();

  // Syria routes.
  const countries = [
    "ALGERIA",
    "ARGENTINA",
    "AUSTRALIA",
    "AUSTRIA",
    "BANGLADESH",
    "BELGIUM",
    "BOLIVIA",
    "BRAZIL",
    "CAMBODIA",
    "CANADA",
    "CHILE",
    "CHINA",
    "COLOMBIA",
    "COSTA RICA",
    "ECUADOR",
    "EGYPT",
    "EL SALVADOR",
    "FIJI",
    "FRANCE",
    "GERMANY",
    "GREECE",
    "GUATEMALA",
    "INDIA",
    "INDONESIA",
    "ITALY",
    "JAPAN",
    "KUWAIT",
    "LAOS",
    "MALAYSIA",
    "MEXICO",
    "MOROCCO",
    "MYANMAR",
    "NEPAL",
    "NEW ZEALAND",
    "PAKISTAN",
    "PANAMA",
    "PERU",
    "PHILIPPINES",
    "PORTUGAL",
    "PUERTO RICO",
    "QATAR",
    "SOUTH AFRICA",
    "SAUDI ARABIA",
    "SOUTH KOREA",
    "SPAIN",
    "TAIWAN",
    "THAILAND",
    "TUNISIA",
    "TURKEY",
    "UAE",
    "UK",
    "URUGUAY",
    "USA",
    "VENEZUELA",
    "VIETNAM",
    "SOUTH SUDAN",
    "UGANDA",
    "KENYA",
  ];

  const [pickupLocation, setPickupLocation] = useState([]);
  const [destinationLocation, setDestinationLocation] = useState([]);

  useEffect(() => {
    getLocations();
  }, []);
  const getLocations = async () => {
    try {
      const response = await get("/get-locations", { pickup, destination });
      console.log(response.data);
      const data = response.data;
      setDestinationLocation(data.destinationLocations.sort());
      setPickupLocation(data.pickupLocations.sort());
    } catch (error) {
      console.log(error);
    }
  };

  const deleteRoute = async () => {
    try {
      const response = await post("/delete-route", { pickup, destination });
      console.log(response.data);
      const data = response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getRoute = async () => {
    if (pickup === "" || destination === "") {
      alert("Select Pickup and Destination");
      return setShowRoute(false);
    }
    try {
      setRouteData({ route: [] });
      setRouteErrorMessage(false);
      const response = await post("/get-route", { pickup, destination });
      console.log(response);
      setRouteData(response.data);
    } catch (error) {
      console.log(error);
      setRouteErrorMessage(true);
      setShowRoute(false);
    }
  };
  useEffect(() => {
    updateDeliveryData();
    showRoute === true && getRoute();
  }, [pickup, destination]);

  // update delivery data
  const updateDeliveryData = () => {
    dispatch({ type: "delivery_details", payload: { pickup, destination } });
    console.log(state);
  };

  // Submit Data
  const handleSubmit = async () => {
    try {
      const response = await post("/new-tracking", state);
      response.status === 200 && (window.location.href = "/tracking/list");
      console.log(response);
    } catch (error) {
      console.log(error);
      if (error.response.data.error) {
        error.response.data.error === "Tracking Id Exists"
          ? trackingError()
          : alert("Please fill all form.");
        navi("/dashboard");
      }
    }
  };

  const handleDelete = async () => {
    const confirm = window.confirm("Do you want to delete This route?");

    if (confirm) {
      await deleteRoute();
      alert("Deleted Successfully");
    } else {
      // User clicked "No" (Cancel)
      console.log("User canceled the deletion");
    }
  };

  const trackingError = () => {
    setTrackingErrorMessage(true);
    setShowRoute(false);
  };
  return (
    <div className="routes">
      {trackingErrorMessage && (
        <span className="text-danger">
          Tracking ID exists. Generate a new Tracking ID and submit
        </span>
      )}
      {routeErrorMessage && (
        <div className="alert alert-danger mt-3 text-center">
          <span>Route Not Found</span>
        </div>
      )}
      <div className="head py-3">
        <span className="fs-5 fw-bold">Delivery Details</span>
      </div>
      {/* Select delivery route */}
      <div className="row mb-3">
        <div className="col-md-8 col-lg-6">
          <select
            className="form-select"
            required
            value={pickup}
            onChange={(e) => setPickup(e.target.value)}
          >
            <option value="" selected>
              Select pickup location
            </option>
            {/* <option value="SYRIA">SYRIA</option>
            <option value="UKRAINE">UKRAINE</option>
            <option value="USA">USA</option> */}

            {pickupLocation.map((i, index) => (
              <option value={i} key={index}>
                {i}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-6">
          <select
            className="form-select"
            required
            value={destination}
            onChange={(e) => setDestination(e.target.value)}
          >
            <option value="" selected>
              Select destination location
            </option>
            {destinationLocation.map((i, index) => (
              <option value={i} key={index}>
                {i}
              </option>
            ))}
          </select>
        </div>
      </div>

      {!showRoute && (
        <div
          className="btn-wrap col-lg-6 mx-auto p-2"
          onClick={() => {
            setShowRoute(true);
            getRoute();
          }}
        >
          <button className="btn btn-danger col-12 shadow">View Route</button>
        </div>
      )}

      {/* Display route */}
      {showRoute === true && (
        <div className="route-history">
          <div className="header py-3">
            <div className="span fs-5 fw-bold text-danger">Shipment Route</div>
          </div>
          {routeData.route.map((rte, index) => (
            <div
              className="wrap position-relative row pb-4 align-items-center"
              key={index}
            >
              <div
                className="col-1 icon justify-content-center d-flex align-items-center"
                style={{ height: "100%" }}
              >
                <div className="circle bg-danger"></div>
                <div className="d-flex col-1 p-0 d-flex justify-content-center">
                  <div className="line"></div>
                </div>
              </div>
              <div className="content col-lg-11 col-11">
                <div className="country fs-6 fw-bold">
                  <span className=" mb-3">{rte.country}</span>
                </div>
                <div className="list">
                  <ul>
                    {rte.path.map((pth, index) => (
                      <li className="mb-3" key={index}>
                        <div className="row">
                          <div className="text-wrap col-lg-9 col-8">
                            <span className=" col-12">
                              {pth.message} <br />
                              <b>{pth.location}</b>
                            </span>{" "}
                            <br />
                          </div>
                          <div className="date-wrap col-lg-3 col-3">
                            <span className="fw-bold  mb-1">
                              {pth.futureTime}
                            </span>{" "}
                            <br />
                            <small className="">{pth.futureDate}</small>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
          {routeData.route.length === 0 && <RouteSkeleton />}

          <div className="btn-wrap col-lg-6 mx-auto p-2">
            <button
              className="btn btn-danger col-12 shadow"
              onClick={handleSubmit}
            >
              Create Tracking
            </button>
          </div>
        </div>
      )}
      {routeData.route.length > 0 && (
        <button className="btn btn-danger col-12 shadow" onClick={handleDelete}>
          Delete Tracking
        </button>
      )}
    </div>
  );
}
